import {createAction} from "@reduxjs/toolkit";
import _ from "lodash";

const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
}

const createDialogReducerMixin = (name, topic = []) => {
    const addOn = {};
    topic.map((x) => {
        addOn[`${name}/dialog${capitalizeFirstLetter(x)}`] = (state, action) => {
            const {payload} = action;
            state.dialog = {
                ...state.dialog,
                [`${x}`]: payload.cmd,
                title: payload.title,
                message: payload.message
            }
            return state;
        };
        return 1;
    });

    return {
        ...addOn,
        [`${name}/dialogSelect`]: (state, action) => {
            const {payload} = action;
            state.dialog.select = payload.cmd;
            state.dialog.title = payload.title;
            state.dialog.message = payload.message;
            return state;
        },
        [`${name}/dialogCreate`]: (state, action) => {
            const {payload} = action;
            state.dialog.create = payload.cmd;
            return state;
        },
        [`${name}/dialogDelete`]: (state, action) => {
            const {payload} = action;
            state.dialog.deleted = payload.cmd;
            state.dialog.title = payload.title;
            state.dialog.message = payload.message;
            return state;
        },
        [`${name}/dialogSuccess`]: (state, action) => {
            const {payload} = action;
            state.dialog.success = payload.cmd;
            state.dialog.title = payload.title;
            state.dialog.message = payload.message;
            return state;
        },
        [`${name}/dialogFailure`]: (state, action) => {
            const {payload} = action;
            state.dialog.failure = payload.cmd;
            state.dialog.title = payload.title;
            state.dialog.message = payload.message;
            return state;
        }
    }
};

const createDialogAction = (name, topic = []) => {
    const addOn = {};
    const actionType = {}
    topic.map((x) => {
        addOn[`dialog${capitalizeFirstLetter(x)}`] = createAction(`${name}/dialog${capitalizeFirstLetter(x)}`,
            (cmd, title = '', message = '') => ({
                payload: {cmd, title, message}
            }));
        actionType[`dialog${capitalizeFirstLetter(x)}`] = `${name}/dialog${capitalizeFirstLetter(x)}`
        return 1;
    });
    return {
        action: {
            ...addOn,
            dialogSelect: createAction(`${name}/dialogSelect`,
                (cmd, title = '', message = '') => ({
                    payload: {cmd, title, message}
                })),
            dialogCreate: createAction(`${name}/dialogCreate`,
                (cmd, title = '', message = '') => ({
                    payload: {cmd, title, message}
                })),
            dialogDelete: createAction(`${name}/dialogDelete`,
                (cmd, title = '', message = '') => ({
                    payload: {cmd, title, message}
                })),
            dialogSuccess: createAction(`${name}/dialogSuccess`,
                (cmd, title = '', message = '') => ({
                    payload: {cmd, title, message}
                })),
            dialogFailure: createAction(`${name}/dialogFailure`,
                (cmd, title = '', message = '') => ({
                    payload: {cmd, title, message}
                }))
        },
        actionType: {
            dialogSelect: `${name}/dialogSelect`,
            dialogCreate: `${name}/dialogCreate`,
            dialogDelete: `${name}/dialogDelete`,
            dialogSuccess: `${name}/dialogSuccess`,
            dialogFailure: `${name}/dialogFailure`,
            ...actionType
        }
    }
}

export {
    createDialogReducerMixin,
    createDialogAction
}
