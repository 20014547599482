import { defaultAction, defaultActionTypes, USER_ACCOUNT } from "../../reducers/UserAccountReducer";
import { createEntitiesAction } from "../../../util/rudux-sagas/mixin/entitiesMixin";
import { createFormAction } from "../../../util/rudux-sagas/mixin/formMixin";
import { createDialogAction } from "../../../util/rudux-sagas/mixin/dialogMixin";
import { createAction } from "@reduxjs/toolkit";

const extraAction = createEntitiesAction(USER_ACCOUNT);
const formAction = createFormAction(USER_ACCOUNT);
const dialogAction = createDialogAction(USER_ACCOUNT, ['changePassword']);

export const userAccountAction = {
    ...defaultAction,
    ...extraAction.action,
    ...dialogAction.action,
    changePassword: createAction(`${USER_ACCOUNT}/changePassword`)
};
export const userAccountActionType = {
    ...defaultActionTypes,
    ...extraAction.actionType,
    ...formAction.actionType,
    ...dialogAction.actionType,
    changePassword: `${USER_ACCOUNT}/changePassword`
}
