import {createSlice} from '@reduxjs/toolkit'
import {asyncState} from "../../util/rudux-sagas/initState";

// initial state
const INIT_STATE = {
    isAuthenticate: false,
    user: null,
    userGroup: '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        ...INIT_STATE,
        ...asyncState
    },
    reducers: {
        logout: (state, action) => ({
            ...INIT_STATE,
            ...asyncState
        })
    },
    extraReducers: {
        'auth/login/pending': (state, action) => ({
            ...state,
            ...asyncState,
            isLoading: true
        }),
        'auth/login/success': (state, action) => {
            const {result} = action.payload;
            state.isLoading = false;
            state.user = result.info.user;
            state.userGroup = result.info.user.group;
            state.isAuthenticate = true;
        },
        'auth/login/failure': (state, action) => ({
            ...state,
            ...asyncState,
            isFailure: true
        })
    }
})

export const defaultAction = authSlice.actions;
export default authSlice.reducer;
