import {createAction} from '@reduxjs/toolkit'
import {defaultAction as authAction} from "../../reducers/AuthenticateReducer";

export const AUTH_LOGIN_ACTION = 'auth/login';
export const AUTH_LOGOUT_ACTION = 'auth/logout';

export const logoutAction = authAction.logout;
export const loginAction = createAction(AUTH_LOGIN_ACTION, (username, password) => ({
    payload: {username, password}
}));
