import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { asyncState, createDialog, createFilter, createServerState } from "../../util/rudux-sagas/initState";
import { createServerReducerMixin } from "../../util/rudux-sagas/mixin/serverMixin";
import {
    createEntitiesAsyncReducerMixin,
    createEntitiesReducerMixin
} from "../../util/rudux-sagas/mixin/entitiesMixin";
import { getActionType } from "../../util/rudux-sagas/getActionType";
import { createFormReducerMixin } from "../../util/rudux-sagas/mixin/formMixin";
import { createDialogReducerMixin } from "../../util/rudux-sagas/mixin/dialogMixin";
import moment from "moment";

export const clinicFormEntitiesAdapter = createEntityAdapter({
    selectId: (user) => user.id,
})


export const CLINIC_FORM = 'clinicForm';
export const ClinicFormInitForm = {
    dateIssue: moment().toISOString(),
    questions: [],
    score: 0,
    applicationType: null,
}

const clinicFormSlice = createSlice({
    name: CLINIC_FORM,
    initialState: clinicFormEntitiesAdapter.getInitialState({
        ...asyncState,
        selectInstance: null,
        server: { ...createServerState() },
        dialog: { ...createDialog([]) },
        filter: { ...createFilter([]) },
        form: { ...ClinicFormInitForm },
    }),
    reducers: {
        ...createServerReducerMixin(CLINIC_FORM),
        ...createEntitiesReducerMixin(CLINIC_FORM, clinicFormEntitiesAdapter),
        ...createFormReducerMixin(CLINIC_FORM)
    },
    extraReducers: {
        ...createEntitiesAsyncReducerMixin(CLINIC_FORM, clinicFormEntitiesAdapter),
        ...createDialogReducerMixin(CLINIC_FORM, []),
    }
});

export const defaultAction = clinicFormSlice.actions;
export const defaultActionTypes = getActionType(CLINIC_FORM, clinicFormSlice.actions);
export const clinicFormSelector = clinicFormEntitiesAdapter.getSelectors;
export default clinicFormSlice.reducer;
