import {all} from 'redux-saga/effects';
import authenticateSagas from './Authenticate';
import userAccountSagas from './UserAccount';
import patientSagas from './Patient';
import clinicFormSagas from './ClinicForm';

export default function* rootSaga(getState) {
    yield all([
        authenticateSagas(),
        userAccountSagas(),
        patientSagas(),
        clinicFormSagas(),
    ])
};
