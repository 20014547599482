export const asyncState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
}

export const createServerState = (defaultSize = 5) => ({
    next: '',
    previous: '',
    currentPage: 0,
    sizePage: defaultSize,
    count: 0
})

export const createDialog = (topic = []) => {
    const addOn = topic.map((x) => ({[x]: false}));
    return {
        ...addOn,
        select: false,
        create: false,
        deleted: false,
        success: false,
        failure: false,
        title: 'Header',
        message: 'message'
    };
}

export const createFilter = (topic = []) => {
    const addOn = topic.map((x) => ({[x[0]]: x[1]}));
    return {
        ...addOn,
        active: false,
        search: '',
        sortColumn: '',
        dir: '',
    };
}
