import { call, fork, put, select, throttle, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { createDefaultSagasRoutine } from '../../../util/rudux-sagas/defaultSagaRoutine';
import { clinicFormActionType, clinicFormAction } from '../../Actions';
import { createFailureAction, createPendingAction, createSuccessAction } from '../../../util/rudux-sagas/createAction';
import { selectClinicForm } from "../../selector/clinicForm";
import snakecaseKeys from 'snakecase-keys';
import moment from "moment";
import { CLINIC_FORM } from "../../reducers/ClinicFormReducer";

const endPoint = 'elderly_clinic';

const extraConfig = {
    fetchAfterCreateUpdate: false,
    resetFormAfterCreateUpdate: false,
    createBody: (state, allState) => {
        const { form } = state;
        const { selectInstance } = allState.patient;
        const postData = {
            patient: selectInstance.id,
            dateIssue: moment(form.dateIssue).format("YYYY-MM-DD"),
            formType: form.applicationType,
            formData: form.questions,
            score: form.score,
        };
        return snakecaseKeys(postData);
    },
    createUpdateBody: (state) => {
        const { form } = state;
        const postData = {
            formData: form.questions,
            score: form.score,
        };
        return snakecaseKeys(postData);
    },
    afterCreateSuccess: function* () {
        yield put(clinicFormAction.dialogSuccess(true, "เสร็จสิ้น", "ทำรายการเสร็จสิ้น"));
    },
    afterCreateFailure: function* () {
        // yield put(patientAction.dialogCreate(false));
    },
    afterUpdateSuccess: function* () {
        yield put(clinicFormAction.dialogSuccess(true, "เสร็จสิ้น", "ทำรายการเสร็จสิ้น"));
    },
    afterDeletedSuccess: function* () {
    },
    afterDeletedFailure: function* () {
    },
    routine: [
        // fork(function* () {
        //     yield takeLatest(patientAction.select, function* () {
        //         let state = yield select(selectPatient);
        //         yield put(patientAction.setForm({
        //             ...state.selectInstance
        //         }));
        //     });
        // })
    ],
    filterMap: {
        'hnNumber': 'patient__hn_number',
        'name': 'patient__name'
    }
}

export default createDefaultSagasRoutine(CLINIC_FORM, endPoint, clinicFormActionType, selectClinicForm, extraConfig);

