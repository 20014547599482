import { defaultAction, defaultActionTypes, PATIENT } from "../../reducers/PatientReducer";
import { createEntitiesAction } from "../../../util/rudux-sagas/mixin/entitiesMixin";
import { createFormAction } from "../../../util/rudux-sagas/mixin/formMixin";
import { createDialogAction } from "../../../util/rudux-sagas/mixin/dialogMixin";
import { createServerAction } from "../../../util/rudux-sagas/mixin/serverMixin";

const extraAction = createEntitiesAction(PATIENT);
const formAction = createFormAction(PATIENT);
const dialogAction = createDialogAction(PATIENT, []);
const serverAction =createServerAction(PATIENT);

export const patientAction = {
    ...defaultAction,
    ...formAction.action,
    ...extraAction.action,
    ...dialogAction.action,
    ...serverAction.action,
};
export const patientActionType = {
    ...defaultActionTypes,
    ...extraAction.actionType,
    ...formAction.actionType,
    ...dialogAction.actionType,
    ...serverAction.actionType
}
