import {createSelector} from "@reduxjs/toolkit";


export const selectLayout = createSelector(
    state => state.layout,
    store => store
);

export const selectLayoutShowSidebar = createSelector(
    state => state.layout,
    store => store?.showSidebar
);
