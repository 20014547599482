import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { asyncState, createDialog, createFilter, createServerState } from "../../util/rudux-sagas/initState";
import { createServerReducerMixin } from "../../util/rudux-sagas/mixin/serverMixin";
import {
    createEntitiesAsyncReducerMixin,
    createEntitiesReducerMixin
} from "../../util/rudux-sagas/mixin/entitiesMixin";
import { getActionType } from "../../util/rudux-sagas/getActionType";
import { createFormReducerMixin } from "../../util/rudux-sagas/mixin/formMixin";
import { createDialogReducerMixin } from "../../util/rudux-sagas/mixin/dialogMixin";
import moment from "moment";

export const patentEntitiesAdapter = createEntityAdapter({
    selectId: (user) => user.id,
})

export const PATIENT = 'patient';
export const PatientInitForm = {
    hnNumber: '',
    name: '',
    idNumber: '',
    nameTitle: 'นาย',
    sex: 'M',
    age: '',
    weight: '',
    height: '',
    status: '1',
    nationality: '',
    birthday: moment().toISOString(),
    occupation: '',
    address: '',
    subDistrict: '',
    district: '',
    province: '',
    postCode: '',
    telephone: '',
    contactPerson: '',
    contactRelation: '',
    contactTelephone: '',
    right: '',
}

const patientSlice = createSlice({
    name: PATIENT,
    initialState: patentEntitiesAdapter.getInitialState({
        ...asyncState,
        selectInstance: null,
        server: { ...createServerState() },
        dialog: { ...createDialog([]) },
        filter: { ...createFilter([]) },
        form: { ...PatientInitForm },
    }),
    reducers: {
        ...createServerReducerMixin(PATIENT),
        ...createEntitiesReducerMixin(PATIENT, patentEntitiesAdapter),
        ...createFormReducerMixin(PATIENT)
    },
    extraReducers: {
        ...createEntitiesAsyncReducerMixin(PATIENT, patentEntitiesAdapter),
        ...createDialogReducerMixin(PATIENT, [])
    }
});
export const defaultAction = patientSlice.actions;
export const defaultActionTypes = getActionType(PATIENT, patientSlice.actions);
export const patentEntitiesSelector = patentEntitiesAdapter.getSelectors;
export default patientSlice.reducer;
