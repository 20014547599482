import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from "@material-ui/core/styles";

export const screen = {
    width: {
        desktop: 1200,
        tablet: 768,
        mobile: 480,
    },
};

const useResponsive = () => {
    const theme = useTheme();
    const isDesktopOrLaptop = useMediaQuery(`(min-width: ${screen.width.desktop}px)`);
    const isBigScreen = useMediaQuery('(min-width: 1824px)');
    const isTabletOrMobile = useMediaQuery( `(max-width: ${screen.width.desktop}px)`);
    const isTablet = useMediaQuery(`(max-width: ${screen.width.tablet}px)`);
    const isMobile = useMediaQuery(`(max-width: ${screen.width.mobile}px)`);
    const isPortrait = useMediaQuery('(orientation: portrait)');
    const isRetina = useMediaQuery('(min-resolution: 2dppx)');
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return {
        isBigScreen,
        isDesktopOrLaptop,
        isMobile,
        isTabletOrMobile,
        isTablet,
        isPortrait,
        isRetina,
        isMdUp
    };
};

export default useResponsive;
