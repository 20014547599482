import React from 'react';
import { Route, Redirect } from 'react-router-dom';
//==================================== MUI-IMPORT ====================================================================//
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
//==================================== Utility =======================================================================//
//==================================== Redux action ==================================================================//
//==================================== Styles ========================================================================//
//==================================== Component definition ===========================================================//
const PrivateRoute = ({component: Component, isAuthenticate, redirect, ...rest}) => {
    return (
        <Route {...rest}
               render={props => {
                   return isAuthenticate ?
                       <Component {...props} />
                       : <Redirect to={redirect} />
               }}
         />
    );
};
//==================================== Component props ===============================================================//
//==================================== Redux map props ===============================================================//
//==================================== Export ========================================================================//
export default PrivateRoute;
