import './App.css';
import './assets/css/material-design-iconic-font.min.css'
import React from "react";
//==================================== MUI-IMPORT ====================================================================//
import {MuiThemeProvider} from '@material-ui/core';
import {
    HashRouter as Router, Redirect, Route, Switch
} from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
import AdminPage from "./pages/Admin";
//==================================== Utility =======================================================================//
import appRoute from './route';
//==================================== Redux action ==================================================================//
import {Provider} from 'react-redux'
import store, {persistor} from './redux/store';
import {PersistGate} from "redux-persist/integration/react";
//==================================== Styles ========================================================================//
import theme from './Theme';
//==================================== Component definition ===========================================================//
function App() {
    React.useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }, false);
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MuiThemeProvider theme={theme}>
                        <Router>
                            <Switch>
                                <Redirect exact from='/' to='admin/dashboard'/>
                                <Route path="/admin" name="admin" render={() => <AdminPage />} />
                                {
                                    appRoute.publicRoute.map((route, idx) => (
                                        (route.component) ? (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                render={props => (
                                                    <route.component {...props} />
                                                )}/>) : ''
                                    ))
                                }
                            </Switch>
                        </Router>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>

            </PersistGate>
        </Provider>
    );
}
//==================================== Component props ===============================================================//
//==================================== Export ========================================================================//
export default App;
