//= =================================== IMPORT =================================================//
import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
//= =================================== Utility =========================================//
import { v4 as uuidv4 } from 'uuid';
import { isTokenExpires } from '../util/authenticate';
//= =================================== Class/Code definition ===================================//
const selectHost = 'https://opdplus.p-enterprise.com/api';
// const selectHost = 'http://localhost:8000';
export const backendConfig = {
    server: selectHost,
    clientId: 'iuiyrwlsz7FLTfiOnNimI4VWlnqf9S311IQYU3oO',
    clientSecrect: 'kjyrqgxV5dhzTKgiZPAjwQiqlQfmki4qVh9itEmsE29FT7vXwJwtUgKPlTxKTOV8bm7vn8lmZv6dAHN8CAe4g1oS00Umf1c7FOfccKpKJVQieNxxCtq2LvaWWAeUjBOq'
};

if(process.env.NODE_ENV === 'production') {

}

class HttpClient {
    static PUBLIC_SCOPE = 'PUBLIC';
    static PRIVATE_SCOPE = 'PRIVATE_SCOPE';

    constructor(props) {
        this.httpClient = axios.create({
            baseURL: backendConfig.server,
            timeout: 3000,
        });
        this.token = null;
        this.currentScope = HttpClient.PUBLIC_SCOPE;
    }

    getClient() {
        return this.httpClient;
    }

    applyToken(token) {
        this.token = token;
    }

    async refreshToken() {
        return new Promise((resolve) => {
            resolve(true);
        });
    }

    async preRequestState() {
        if (isTokenExpires(this.token.expiresIn)) {
            // TODO
            // Waiting for api completed
            await this.refreshToken();
        }
        return true;
    }

    async get(uri, config = {}, preCheck = true) {
        // TODO
        // Ignore expired token check
        if (!preCheck) {
            await this.preRequestState();
        }
        let res;
        try {
            res = await this.httpClient.get(uri, this.createConfig(config));
        } catch (e) {
            throw e;
        }
        return res;
    }

    async post(uri, data, config = {}, preCheck = true) {
        // TODO
        // Ignore expired token check
        if (!preCheck) {
            await this.preRequestState();
        }
        let res;
        try {
            res = await this.httpClient.post(uri, data, this.createConfig(config));
        } catch (e) {
            throw e;
        }
        return res;
    }

    async put(uri, data, config = {}, preCheck = true) {
        // TODO
        // Ignore expired token check
        if (!preCheck) {
            await this.preRequestState();
        }
        let res;
        try {
            res = await this.httpClient.put(uri, data, this.createConfig(config));
        } catch (e) {
            throw e;
        }
        return res;
    }

    async patch(uri, data, config = {}, preCheck = true) {
        // TODO
        // Ignore expired token check
        if (!preCheck) {
            await this.preRequestState();
        }
        let res;
        try {
            res = await this.httpClient.patch(uri, data, this.createConfig(config));
        } catch (e) {
            throw e;
        }
        return res;
    }

    async delete(uri, config = {}, preCheck = true) {
        // TODO
        // Ignore expired token check
        if (!preCheck) {
            await this.preRequestState();
        }
        let res;
        try {
            res = await this.httpClient.delete(uri, this.createConfig(config));
        } catch (e) {
            throw e;
        }
        return res;
    }

    createConfig(config, useDefaultAuthenticate = true) {
        const addOnHeader = {
            'X-Correlation-ID': uuidv4(),
        };
        if (useDefaultAuthenticate && !_.isEmpty(this.token.accessToken)) {
            addOnHeader.Authorization = `${this.token.accessToken}`;
        }
        return {
            ...config,
            headers: {
                ...config?.headers,
                ...addOnHeader,
            },
        };
    }
}

//= =================================== Export ===================================================//
export default new HttpClient();
