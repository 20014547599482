import { createFailureAction, createSuccessAction } from "../createAction";
import { call, put, select } from "redux-saga/effects";
import httpClient from "../../../Api/httpClient";
import { snakeCase} from "case-anything";
import _ from 'lodash';
import snakecaseKeys from "snakecase-keys";

export function* getList (endpoint, appActions, selector, pendingAction, extraConfig, action ) {
    const successAction = createSuccessAction(appActions?.get);
    const failureAction = createFailureAction(appActions?.get);
    yield put(pendingAction());
    let store = yield select(selector);
    try {
        const request = yield call(async (state) => {
            const { filter, server } = state;
            const { currentPage, sizePage } = server;
            const qparams = {
                search: filter?.search,
                size: sizePage
            };
            if (qparams.search === '') {
                delete qparams['search'];
            }
            if (currentPage + 1 > 1) {
                qparams['page'] = currentPage + 1
            }

            if (filter.sortColumn) {
                const orderingMap = extraConfig.filterMap;
                let orderKey = filter.sortColumn;
                if (orderingMap) {
                    orderKey = _.get(orderingMap, filter.sortColumn, filter.sortColumn);
                }
                if (filter.dir === 'desc') {
                    qparams['ordering'] = `-${orderKey}`;
                } else {
                    qparams['ordering'] = `${orderKey}`;
                }
            }
            let addOnFilter = snakecaseKeys(_.omit(filter, ['active', 'search', 'sortColumn', 'dir']));

            const response = await httpClient.get(endpoint, {
                params: {
                    ...qparams,
                    ...addOnFilter,
                }
            });
            return response.data;
        }, store);
        yield put(successAction({ result: request }));
    } catch (error) {
        yield put(failureAction({ result: error.response }));
    }
}
