import {all, call, fork, put, takeEvery, take, debounce, select, throttle} from 'redux-saga/effects';
import {AUTH_LOGIN_ACTION, AUTH_LOGOUT_ACTION} from "../../Actions/Authentication";
import {createFailureAction, createPendingAction, createSuccessAction} from "../../../util/rudux-sagas/createAction";
import {obtainToken, revokeToken} from "../../../util/authenticate";

export default function* rootSaga() {
    yield all([
        fork( function* () {
            yield throttle(3000, AUTH_LOGIN_ACTION, function* (action) {
                const {payload} = action;
                const loginPendingAction = createPendingAction(AUTH_LOGIN_ACTION);
                const loginSuccessAction = createSuccessAction(AUTH_LOGIN_ACTION);
                const loginFailureAction = createFailureAction(AUTH_LOGIN_ACTION);
                try {
                    yield put(loginPendingAction());
                    const response = yield call( async (form) => {
                        const data = await obtainToken(form.username, form.password);
                        return data;
                    }, payload);
                    yield put(loginSuccessAction({result: response}));
                } catch (error) {
                    yield put(loginFailureAction({result: error.response}));
                }
            });
        }),
        fork( function* () {
            yield takeEvery(AUTH_LOGOUT_ACTION, function* (action) {
                try {
                    yield call( async () => {
                        await revokeToken();
                    })
                } catch (error) {

                }
            })
        })
    ])
}
