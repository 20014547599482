import {createAction} from "@reduxjs/toolkit";
import moment from "moment";

const createFormReducerMixin = (name) => {
    return {
        setForm: (state, action) => {
            state.form = {
                ...state.form,
                ...action.payload
            };
        },
        formChange: (state, action) => {
            const {payload} = action
            state.form = {
                ...state.form,
                [payload.name]: payload.value,
            };
            return state;
        },
        formReset: (state) => {
            Object.keys(state.form).map((x) => state.form[x] = '');
            if ('dateIssue' in state.form) {
                state.form.dateIssue = moment().toISOString();
            }
            return state;
        }
    }
};

const createFormAction = (name) => {
    return {
        action: {
            formChange: createAction(`${name}/formChange`, (name, value) => ({
                payload: {name, value}
            })),
            setForm: createAction(`${name}/setForm`, (payload) => ({
                payload
            })),
            formReset: createAction(`${name}/formReset`)
        },
        actionType: {
            setForm: `${name}/setForm`,
            formChange: `${name}/formChange`,
            formReset: `${name}/formReset`,
        }
    }
}

export {
    createFormAction,
    createFormReducerMixin
}
