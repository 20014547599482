import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { asyncState, createDialog, createFilter, createServerState } from "../../util/rudux-sagas/initState";
import { createServerReducerMixin } from "../../util/rudux-sagas/mixin/serverMixin";
import {
    createEntitiesAsyncReducerMixin,
    createEntitiesReducerMixin
} from "../../util/rudux-sagas/mixin/entitiesMixin";
import { getActionType } from "../../util/rudux-sagas/getActionType";
import { createFormReducerMixin } from "../../util/rudux-sagas/mixin/formMixin";
import { createDialogReducerMixin } from "../../util/rudux-sagas/mixin/dialogMixin";

export const userAccountAdapter = createEntityAdapter({
    selectId: (user) => user.id,
})

export const USER_ACCOUNT = 'userAccount';
export const UserAccountInitForm = {
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    email: 'test@test.com',
    group: '*',
}

const userAccountSlice = createSlice({
    name: USER_ACCOUNT,
    initialState: userAccountAdapter.getInitialState({
        ...asyncState,
        selectInstance: null,
        server: { ...createServerState() },
        dialog: { ...createDialog(['changePassword']) },
        filter: { ...createFilter() },
        form: { ...UserAccountInitForm },
    }),
    reducers: {
        setForm: (state, action) => {
          state.form = {
              ...state.form,
              ...action.payload
          };
        },
        ...createServerReducerMixin(USER_ACCOUNT),
        ...createEntitiesReducerMixin(USER_ACCOUNT, userAccountAdapter),
        ...createFormReducerMixin(USER_ACCOUNT)
    },
    extraReducers: {
        ...createEntitiesAsyncReducerMixin(USER_ACCOUNT, userAccountAdapter),
        ...createDialogReducerMixin(USER_ACCOUNT, ['changePassword'])
    }
});
export const defaultAction = userAccountSlice.actions;
export const defaultActionTypes = getActionType(USER_ACCOUNT, userAccountSlice.actions);
export const userAccountSelector = userAccountAdapter.getSelectors;
export default userAccountSlice.reducer;
