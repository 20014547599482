import {createAction, createSlice} from '@reduxjs/toolkit'
import AppConfig from '../../constants/AppConfig';
import navLinks from '../../sidebarItems';

const layoutSlice =  createSlice({
    name: 'LayoutSlice',
    initialState: {
        appName: AppConfig.appName,
        showSidebar: AppConfig.navCollapsed,
        sidebarMenus: navLinks
    },
    reducers: {
        showSideBar: (state, action) => {
            state.showSidebar = action.payload
        }
    },
    extraReducers: {

    }
});

export const toggleSideBarAction = layoutSlice.actions.showSideBar;

export default layoutSlice.reducer;

