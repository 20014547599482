import { createSelector } from "@reduxjs/toolkit";
import _ from 'lodash';
import { pascalCase } from "case-anything";

export const createDefaultSelector = (topic, entitiesSelector) => {
    return {
        [`select${pascalCase(topic)}`]: createSelector(
            state => _.get(state, topic),
            store => store
        ),
        [`select${pascalCase(topic)}Loading`]: createSelector(
            state => _.get(state, topic),
            store => store.isLoading
        ),
        [`select${pascalCase(topic)}Entities`]: createSelector(
            state => _.get(state, topic),
            store => entitiesSelector().selectAll(store)
        ),
        [`select${pascalCase(topic)}IsSelectInstance`]: createSelector(
            state => _.get(state, topic),
            store => store.selectInstance !== null
        ),
        [`select${pascalCase(topic)}Table`]: createSelector(
            state => _.get(state, topic),
            store => store.server
        ),
        [`select${pascalCase(topic)}Form`]: createSelector(
            state => _.get(state, topic),
            store => store.form
        ),
        [`select${pascalCase(topic)}Dialog`]: createSelector(
            state => _.get(state, topic),
            store => store.dialog
        ),
        [`select${pascalCase(topic)}Filter`]: createSelector(
            state => _.get(state, topic),
            store => store.filter
        ),
    }
}
