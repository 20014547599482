import React from 'react';
import PropTypes from 'prop-types';
//==================================== MUI-IMPORT ====================================================================//
import {makeStyles, } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
//==================================== Utility =======================================================================//
//==================================== Redux action ==================================================================//
//==================================== Styles ========================================================================//
const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
        minHeight: 32,
        width: '100%',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
            height: '100%'
        },
        '@media (orientation: portrait)': {
            position: 'fixed',
            height: 'auto !important',
            bottom: 0,
            zIndex: 100,
        }
    }
}));
//==================================== Component definition ===========================================================//
const Footer = (props) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={clsx(classes.root, )}
        >
            <Grid item>
                Power by Anatthra © 2021 {props.appName}, All rights reserved.
            </Grid>
        </Grid>
    )
}
//==================================== Component props ===============================================================//
Footer.propTypes = {
    appName: PropTypes.string
}

Footer.defaultProps = {
    appName: ""
}
//==================================== Redux map props ===============================================================//
//==================================== Export ========================================================================//
export default Footer;
