import { createFailureAction, createSuccessAction } from "../createAction";
import { call, put, select } from "redux-saga/effects";
import httpClient from "../../../Api/httpClient";
import _ from "lodash";

export function* createInstance (endpoint, appActions, selector, pendingAction, extraConfig, action ) {
    const successAction = createSuccessAction(action?.type);
    const failureAction = createFailureAction(action?.type);
    yield put(pendingAction());
    let store = yield select(selector);
    let globalState = yield select(state => state);
    try {
        const postData = extraConfig.createBody(store, globalState);
        const req = yield call(async () => {
            const response = await httpClient.post(endpoint, postData);
            return response.data;
        });
        yield put(successAction({ result: req }));

        if (_.get(extraConfig, 'fetchAfterCreateUpdate', true)) {
            yield put({ type: appActions.get });
        }

        if (_.get(extraConfig, 'resetFormAfterCreateUpdate', true)) {
            yield put({ type: appActions.formReset });
        }

        if (_.isFunction(extraConfig.afterCreateSuccess)) {
            yield call(extraConfig.afterCreateSuccess);
        }
    } catch (error) {
        console.log(error);
        yield put(failureAction({ result: error.response }));
        if (_.isFunction(extraConfig.afterCreateFailure)) {
            yield call(extraConfig.afterCreateFailure);
        }
    }
}
