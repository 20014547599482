import {createSelector} from "@reduxjs/toolkit";

export const selectAuthenticateUser = createSelector(
    state => state.authenticate,
    store => store?.user
);

export const selectIsLogin = createSelector(
    state => state.authenticate,
    store => store?.isAuthenticate
);
