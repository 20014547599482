import { all, debounce, fork, throttle } from "redux-saga/effects";
import _ from "lodash";
import { createPendingAction } from "../createAction";
import { getList } from "./get";
import { createInstance } from "./create";
import { deleteInstance } from "./delete";
import { updateInstance } from "./update";

export const createDefaultSagasRoutine = (name, endpoint, actions, selector, extraConfig = {}, throttleTime = 2000, debounceTime = 1000) => {
    return function* rootSaga() {
        const pendingAction = createPendingAction(name);
        yield all([
            fork(function* () {
                yield debounce(
                    debounceTime,
                    [
                        actions.search, actions.tablePageChange, actions.tablePageSizeChange, actions.updateFilter,
                        actions.sortFilter, actions.clearFilter
                    ],
                    getList, endpoint, actions, selector, pendingAction, extraConfig
                );
            }),
            fork(function* () {
                yield throttle(
                    1000,
                    [actions.get, ],
                    getList, endpoint, actions, selector, pendingAction, extraConfig
                );
            }),
            fork(function* () {
                yield throttle(throttleTime,
                    actions.create,
                    createInstance,
                    endpoint, actions, selector, pendingAction, extraConfig
                );
            }),
            fork(function* () {
                yield throttle(throttleTime,
                    actions.update,
                    updateInstance,
                    endpoint, actions, selector, pendingAction, extraConfig
                    );
            }),
            fork(function* () {
                yield throttle(throttleTime,
                    actions.delete,
                    deleteInstance,
                    endpoint, actions, selector, pendingAction, extraConfig
                );
            }),
            ..._.get(extraConfig, ['routine'], []),
        ]);

    }
}
