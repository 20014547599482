import { call, fork, put, select, throttle, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { USER_ACCOUNT } from '../../reducers/UserAccountReducer';
import { selectUserAccount } from '../../selector/userAccount';
import { createDefaultSagasRoutine } from '../../../util/rudux-sagas/defaultSagaRoutine';
import { userAccountActionType, userAccountAction } from '../../Actions/UserAccount';
import { createFailureAction, createPendingAction, createSuccessAction } from '../../../util/rudux-sagas/createAction';
import httpClient from '../../../Api/httpClient';

const endPoint = 'authorize/user';
const pendingAction = createPendingAction(USER_ACCOUNT);

const extraConfig = {
    createBody: (state) => {
        const { form } = state;
        return {
            'user': {
                'username': form.username,
                'first_name': form.firstName,
                'last_name': form.lastName,
                'email': form.email,
                'password': form.password,
            }
        }
    },
    createUpdateBody: (state) => {
        const { form } = state;
        return {
            'user': {
                'first_name': form.firstName,
                'last_name': form.lastName,
            }
        }
    },
    afterCreateSuccess: function* () {
        yield put(userAccountAction.dialogCreate(false));
    },
    afterCreateFailure: function* () {
        yield put(userAccountAction.dialogCreate(false));
    },
    afterDeletedSuccess: function* () {
        yield put(userAccountAction.dialogDelete(false));
    },
    afterDeletedFailure: function* () {
        yield put(userAccountAction.dialogDelete(false));
    },
    routine: [
        fork(function* () {
            yield throttle(3000, userAccountActionType.changePassword, function* (action) {
                const successAction = createSuccessAction(action?.type);
                const failureAction = createFailureAction(action?.type);
                put(pendingAction());
                let state = yield select(selectUserAccount);
                const { form } = state;
                try {
                    const request = yield call(async () => {
                        const response = await httpClient.put(`${endPoint}/${form.id}/reset_password`, {
                            password: action.payload
                        });
                        return response.data;
                    });
                    yield put(successAction({ result: request }));
                } catch (error) {
                    yield put(failureAction({ result: error.response }));
                }
            })
        }),
        fork(function* () {
            yield takeLatest(userAccountActionType.select, function* () {
                let state = yield select(selectUserAccount);
                yield put(userAccountAction.setForm({
                    ..._.pick(state.selectInstance.user, ['username', 'firstName', 'lastName', 'group'])
                }));
            });
        })
    ]
}

export default createDefaultSagasRoutine(USER_ACCOUNT, 'authorize/user',
    userAccountActionType, selectUserAccount, extraConfig);

