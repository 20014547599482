import { createSelector } from "@reduxjs/toolkit";
import _ from 'lodash';
import { clinicFormSelector } from "../reducers/ClinicFormReducer";
import { createDefaultSelector } from "../../util/rudux-sagas/selector";

const selectTopic = 'clinicForm'

export const {
    selectClinicForm,
    selectClinicFormLoading,
    selectClinicFormEntities,
    selectClinicFormIsSelectInstance,
    selectClinicFormTable,
    selectClinicFormDialog,
    selectClinicFormForm,
    selectClinicFormFilter,
} = createDefaultSelector(selectTopic, clinicFormSelector);

export const selectClinicFormState = createSelector(
    state => _.get(state, selectTopic),
    store => store
);

export const selectClinicFormInstance = createSelector(
    selectClinicFormState,
    store => store.selectInstance
);

export const selectClinicFormDataTableEntities = createSelector(
    selectClinicFormState,
    store => {
        const entities = clinicFormSelector().selectAll(store);
        const data = _.toArray(entities);
        return data.map((x) => {
            return {
                ...x,
            };
        });
    }
);

export const selectClinicFormDataMNATableEntities = createSelector(
    selectClinicFormState,
    store => {
        const entities = clinicFormSelector().selectAll(store);
        const data = _.toArray(entities);
        return data.map((x) => {
            let score = {
                score: x.score,
                pass: false,
                riskLevel: 0
            }
            if (_.isNumber(x.formData.group1Score)) {
                if (x.formData.group1Score > 11) {
                    score = {
                        pass: true,
                        score: x.formData.group1Score
                    };
                } else {
                    if (x.score <= 17) {
                        score = {
                            pass: false,
                            score: x.score,
                            riskLevel: 2
                        };
                    } else if (17 < x.score && x.score <= 23.5) {
                        score = {
                            pass: false,
                            score: x.score,
                            riskLevel: 1
                        };
                    } else {
                        score = {
                            pass: true,
                            score: x.score,
                            riskLevel: 0
                        };
                    }
                }
            }

            return {
                ...x,
                score
            };
        });
    }
);

export const selectClinicFormDataUrinaryTableEntities = createSelector(
    selectClinicFormState,
    store => {
        const entities = clinicFormSelector().selectAll(store);
        const data = _.toArray(entities);
        return data.map((x) => {


            return {
                ...x,
                from: x.formData.question1.value,
            };
        });
    }
);
