import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
//==================================== MUI-IMPORT ====================================================================//
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
//==================================== Utility =======================================================================//
//==================================== Redux action ==================================================================//
//==================================== Styles ========================================================================//
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 120,
        textAlign: 'center',
        paddingTop: 16,
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    centerAlign: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    userName: {
        paddingTop: 8,
    },
    email: {
        marginBottom: 8,
    }
}));
//==================================== Component definition ===========================================================//
const UserProfile = (props) => {
    const classes = useStyles();

    return (
        <Grid container
              justifyContent="center"
              alignItems="center"
              className={"mt-16 mb-16 " + classes.root}
        >
            <Grid item xs={12}>
                <Avatar src={props?.avatar} className={clsx(classes.avatar, classes.centerAlign)}>I</Avatar>
            </Grid>
            <Grid item xs={12} className={classes.userName}>
                <Typography>
                    {props.username}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.email}>
                <Typography>
                    {props.email}
                </Typography>
            </Grid>
        </Grid>
    );
}
//==================================== Component props ===============================================================//
UserProfile.prototype = {
    username: PropTypes.string,
    email: PropTypes.string,
}

UserProfile.defaultProps = {
    username: 'John Doe',
    email: 'Test@test.com',
}
//==================================== Redux map props ===============================================================//
//==================================== Export ========================================================================//
export default UserProfile;
