import {combineReducers} from 'redux';
import authenticateReducer from "./AuthenticateReducer";
import userAccountReducer from "./UserAccountReducer";
import patientReducer  from "./PatientReducer";
import clinicFormReducer  from "./ClinicFormReducer";

import layoutReducer from './LayoutReducer';
const reducers = combineReducers({
    // Framework
    layout: layoutReducer,
    // Authorized
    authenticate: authenticateReducer,
    userAccount: userAccountReducer,
    // Application
    patient: patientReducer,
    clinicForm: clinicFormReducer,

});

export default reducers;
