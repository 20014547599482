import loadable from '@loadable/component'
// TODO
//  Loadable page
// const OtherComponent = loadable(() => import('./OtherComponent'))
import appConfig from './constants/AppConfig';
const AdminDashboard = loadable(() => import('./pages/Admin/dashboard'));
const AdminUser = loadable(() => import('./pages/Admin/user'));
const SignInPage = loadable(() => import('./pages/Auth/signin'));
const AdminPatient = loadable(() => import('./pages/Admin/patient'));
const HeathCheckUpAdd = loadable(() => import('./pages/Admin/Form/HeathCheckUp/add'));
const HeathCheckUpList = loadable(() => import('./pages/Admin/Form/HeathCheckUp'));
const FallRiskAssessmentForm = loadable(() => import('./pages/Admin/Form/FallRiskAssessmentForm/add'));
const FallRiskAssessmentFormList = loadable(() => import('./pages/Admin/Form/FallRiskAssessmentForm'));
const TugtForm = loadable(() => import('./pages/Admin/Form/TimeUpAndGoTest/add'));
const TugtList = loadable(() => import('./pages/Admin/Form/TimeUpAndGoTest'));
const MNA1 = loadable(() => import('./pages/Admin/Form/MNA/mna1'));
const MNA1List = loadable(() => import('./pages/Admin/Form/MNA/mna1list'));
const Mna2Form = loadable(() => import('./pages/Admin/Form/MNA/mna2'));
const MNA2List = loadable(() => import('./pages/Admin/Form/MNA/mna2list'));
const AdlForm = loadable(() => import('./pages/Admin/Form/ADL/AdlForm'));
const AdlList = loadable(() => import('./pages/Admin/Form/ADL'));
const UrinaryProblemForm = loadable(() => import('./pages/Admin/Form/UrinaryProblem/UrinaryProblemForm'));
const UrinaryProblemList = loadable(() => import('./pages/Admin/Form/UrinaryProblem'));
const route = {
    publicRoute: [
        { path: appConfig.signInUrl, exact: false, name: 'Menu', component: SignInPage },
    ],
    privateRoute: [
        { path: '/admin/dashboard', exact: false, name: 'dashboard', component: AdminDashboard },
        { path: '/admin/user', exact: false, name: 'dashboard', component: AdminUser },
        { path: '/admin/patient', exact: false, name: 'patient', component: AdminPatient },
        { path: '/admin/heath-checkup/add', exact: false, name: 'heath-checkup-form', component: HeathCheckUpAdd },
        { path: '/admin/heath-checkup', exact: false, name: 'heath-checkup-form', component: HeathCheckUpList },
        { path: '/admin/fall-risk-assessment/add', exact: false, name: 'fall-risk-assessment-form', component: FallRiskAssessmentForm },
        { path: '/admin/fall-risk-assessment', exact: false, name: 'fall-risk-assessment-form', component: FallRiskAssessmentFormList },
        { path: '/admin/tugt/add', exact: false, name: 'tugt-form', component: TugtForm },
        { path: '/admin/tugt', exact: false, name: 'tugt-form', component: TugtList },
        { path: '/admin/mna', exact: true, name: 'mna1-form', component: MNA1List },
        { path: '/admin/mna/add', exact: false, name: 'mna1-form-add', component: MNA1 },
        // { path: '/admin/mna2', exact: true, name: 'mna2-form', component: MNA2List },
        { path: '/admin/mna2/add', exact: false, name: 'mna2-form-add', component: Mna2Form },
        { path: '/admin/adl-form', exact: true, name: 'adl-form', component: AdlList },
        { path: '/admin/adl-form/add', exact: false, name: 'adl-form-add', component: AdlForm },
        { path: '/admin/urinary-form/add', exact: false, name: 'urinary-form', component: UrinaryProblemForm },
        { path: '/admin/urinary-form', exact: false, name: 'urinary-form', component: UrinaryProblemList },
    ],
}

export default route;
