import {createSelector} from "@reduxjs/toolkit";
import _ from 'lodash';
import { userAccountSelector } from "../reducers/UserAccountReducer";

export const selectUserAccount = createSelector(
    state => state.userAccount,
    store => store
);

export const selectUserAccountLoading = createSelector(
    state => state.userAccount,
    store => store.isLoading
);

export const selectUserAccountEntities = createSelector(
    state => state.userAccount,
    store => userAccountSelector().selectAll(store)
);

export const selectUserAccountIsSelectInstance = createSelector(
    state => state.userAccount,
    store => store.selectInstance !== null
);

export const selectUserAccountDataTableEntities = createSelector(
    state => state.userAccount,
    store => {
        const entities = userAccountSelector().selectAll(store);
        const data = _.toArray(entities);
        return data.map((x) => ({
            ...x,
            ..._.pick(x.user, ['username', 'group', 'isActive'])
        }));
    }
);

export const selectUseAccountTable = createSelector(
    state => state.userAccount,
    store => store.server
)

export const selectUserAccountDialog = createSelector(
    state => state.userAccount,
    store => store.dialog
);

export const selectUserAccountForm = createSelector(
    state => state.userAccount,
    store => store.form
);
