import React from 'react';
import {connect} from 'react-redux';
import clsx from "clsx";
//==================================== MUI-IMPORT ====================================================================//
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles, } from '@material-ui/core/styles';
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import MoreIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
import NotificationsIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from "@material-ui/icons/AccountCircle";
//==================================== App component =================================================================//
//==================================== Utility =======================================================================//
//==================================== Redux action ==================================================================//
import {
    toggleSideBarAction,
    logoutAction
} from "../../../redux/Actions";
//==================================== Styles ========================================================================//
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarSpacer: theme.mixins.toolbar,
    toolbar: {
        marginRight: 16, // keep right padding when drawer closed
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));
//==================================== Component definition ===========================================================//
const AppNavBar = (props) => {
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const {showSidebar, user} = props;
    const menuId = 'primary-account-menu';
    const mobileMenuId = 'primary-account-menu-mobile';

    const handleMenuClose = (event, reason) => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null)
    };


    const handlerLogout = () => {
        handleMenuClose();
        props.logoutAction();
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={!!anchorEl}
            onClose={() => handleMenuClose()}
        >
            <MenuItem onClick={() => handleMenuClose()}>Profile</MenuItem>
            <MenuItem onClick={() => handleMenuClose()}>My account</MenuItem>
            <MenuItem onClick={() => handlerLogout()}>Logout</MenuItem>
        </Menu>
    );

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'left'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
            open={!!mobileMoreAnchorEl}
            onClose={() => setMobileMoreAnchorEl(null)}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon/>
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem onClick={(e) => setAnchorEl(e.currentTarget)}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            <MenuItem onClick={() => handlerLogout()}>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <ExitToAppIcon/>
                    </Badge>
                </IconButton>
                <p>Logout</p>
            </MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            <AppBar position="absolute"
                    color="inherit"
                    className={clsx(classes.appBar)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => props.toggleSideBarAction(!showSidebar)}
                        className={clsx(
                            classes.menuButton,
                        )}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Hidden mdUp>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            OPD-PLUS
                        </Typography>
                    </Hidden>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        <IconButton aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <MailIcon/>
                            </Badge>
                        </IconButton>
                        <IconButton aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={17} color="secondary">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                        <Button aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                color="inherit"
                                endIcon={<AccountCircle />}
                        >
                            {user?.firstName} {user?.lastName ?? '' }
                        </Button>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton aria-label="show 11 new notifications" color="inherit">
                            <Badge badgeContent={11} color="secondary">
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={(e) => setMobileMoreAnchorEl(e.currentTarget)}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </React.Fragment>

    );
}
//==================================== Component props ===============================================================//
const mapStateToProps = ({layout, authenticate}) => {
    return {
        showSidebar: layout.showSidebar,
        user: authenticate.user
    };
};
//==================================== Redux map props ===============================================================//
//==================================== Export ========================================================================//
export default connect(mapStateToProps, {
    toggleSideBarAction,
    logoutAction,
})(AppNavBar);
