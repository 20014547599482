import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Display = (props) => {
    if (props.show) {
        return props.children;
    }
    return '';
};
Display.propTypes = {
    show: PropTypes.bool,
};

Display.defaultProps = {
    show: true,
};

export default Display;
