import { defaultAction, defaultActionTypes, CLINIC_FORM } from "../../reducers/ClinicFormReducer";
import { createEntitiesAction } from "../../../util/rudux-sagas/mixin/entitiesMixin";
import { createFormAction } from "../../../util/rudux-sagas/mixin/formMixin";
import { createDialogAction } from "../../../util/rudux-sagas/mixin/dialogMixin";
import { createServerAction } from "../../../util/rudux-sagas/mixin/serverMixin";

const extraAction = createEntitiesAction(CLINIC_FORM);
const formAction = createFormAction(CLINIC_FORM);
const dialogAction = createDialogAction(CLINIC_FORM, []);
const serverAction =createServerAction(CLINIC_FORM);

export const clinicFormAction = {
    ...defaultAction,
    ...formAction.action,
    ...extraAction.action,
    ...dialogAction.action,
    ...serverAction.action,
};
export const clinicFormActionType = {
    ...defaultActionTypes,
    ...extraAction.actionType,
    ...formAction.actionType,
    ...dialogAction.actionType,
    ...serverAction.actionType,
}
