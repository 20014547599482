import React, { Children } from 'react';
import {
    useLocation
} from "react-router-dom";
//==================================== MUI-IMPORT ====================================================================//
import Box from "@material-ui/core/Box";
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
//==================================== Utility =======================================================================//
//==================================== Redux action ==================================================================//
//==================================== Styles ========================================================================//
//==================================== Component definition ===========================================================//
const ActiveLink = ({ children, ...props }) => {
    const child = Children.only(children);
    const location = useLocation();

    let className = child.props.className || '';
    if (location.pathname.includes(props.href) && props.activeClassName) {
        className = `${className} ${props.activeClassName}`.trim();
    }

    delete props.activeClassName;

    return <Box {...props}>{React.cloneElement(child, { className })}</Box>;
};
//==================================== Component props ===============================================================//
//==================================== Redux map props ===============================================================//
//==================================== Export ========================================================================//
export default ActiveLink;
