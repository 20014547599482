import React from 'react';
import PropTypes from 'prop-types';
//==================================== MUI-IMPORT ====================================================================//
import {makeStyles} from "@material-ui/core/styles";
import AdminLayout from "../../Component/Layout/AdminLayout";
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
import appRoute from '../../route';
import appConfig from '../../constants/AppConfig';
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "../../util/private-route";
import {useSelector} from "react-redux";
import {selectIsLogin} from "../../redux/selector";
//==================================== Utility =======================================================================//
//==================================== Redux action ==================================================================//
//==================================== Styles ========================================================================//
const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1
    },
}));
//==================================== Component definition ===========================================================//
const AdminPage = (props) => {
    const {signInUrl, appName} = appConfig;
    const classes = useStyles();
    const isLogIn = useSelector(selectIsLogin);

    return (
        <AdminLayout appName={appName}>
            <Switch>
                {
                    appRoute.privateRoute.map((route, index) => {
                        return (route.component) ? (
                            <PrivateRoute
                                key={index}
                                isAuthenticate={isLogIn}
                                redirect={signInUrl}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                component={route.component}
                            />
                        ) : null;
                    })
                }
            </Switch>
        </AdminLayout>
    )
}
//==================================== Component props ===============================================================//
AdminPage.propTypes = {
    classes: PropTypes.object,
};

AdminPage.defaultProps = {
    classes: {},
};
//==================================== Redux map props ===============================================================//

//==================================== Export ========================================================================//
export default AdminPage;
