import {createAction} from "@reduxjs/toolkit";
import camelcaseKeys from "camelcase-keys";
import _ from 'lodash';

const createEntitiesReducerMixin = (name, adapter) => {
    return {
        get: (state, action, adapter) => {
            if (_.has(state, 'isLoading')) {
                state.isLoading = true;
            }
            // adapter.addMany(state, action.payload.data);
        },
        clearEntities: ((adapterEntities, state, action) => {
            adapterEntities.removeAll(state);
        }).bind(this, adapter),
        select: ((adapterEntities, state, action) => {
            const selector = adapter.getSelectors();
            state.selectInstance = selector.selectById(state, action.payload);
        }).bind(this, adapter),
        removeSelect: (state, action) => {
            state.selectInstance = null;
        }
    }
}

const createEntitiesAsyncReducerMixin = (name, adapter) => {

    return {
        [`${name}/pending`]: (state, action, adapter) => {
            if (_.has(state, 'isLoading')) {
                state.isLoading = true;
            }
        },
        [`${name}/get/success`]: ((adapterEntities, state, action) => {
            const response = camelcaseKeys(action.payload.result, {deep: true});
            adapterEntities.removeAll(state);
            adapterEntities.addMany(state, response.results);
            if (state.server) {
                state.server.count = response.count;
            }
            if (_.has(state, 'isLoading')) {
                state.isLoading = false;
            }
        }).bind(this, adapter),
        [`${name}/get/failure`]: (state, action) => {
            if (_.has(state, 'isLoading')) {
                state.isLoading = false;
            }
        },
        [`${name}/create/failure`]: (state, action) => {
            if (_.has(state, 'isLoading')) {
                state.isLoading = false;
            }
        },
        [`${name}/update/failure`]: (state, action) => {
            if (_.has(state, 'isLoading')) {
                state.isLoading = false;
            }
        },
        [`${name}/delete/failure`]: (state, action) => {
            if (_.has(state, 'isLoading')) {
                state.isLoading = false;
            }
        },
    }
}


const createEntitiesAction = (name) => {
    return {
        action: {
            update: createAction(`${name}/update`),
            delete: createAction(`${name}/delete`),
            create: createAction(`${name}/create`),
        },
        actionType: {
            get: `${name}/get`,
            select: `${name}/select`,
            update: `${name}/update`,
            delete: `${name}/delete`,
            create: `${name}/create`,
        }
    }
};

export {
    createEntitiesReducerMixin,
    createEntitiesAsyncReducerMixin,
    createEntitiesAction
}
