import { call, fork, put, select, throttle, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { createDefaultSagasRoutine } from '../../../util/rudux-sagas/defaultSagaRoutine';
import { patientActionType, patientAction } from '../../Actions';
import { createFailureAction, createPendingAction, createSuccessAction } from '../../../util/rudux-sagas/createAction';
import httpClient from '../../../Api/httpClient';
import { selectPatient } from "../../selector/patient";
import { PATIENT } from "../../reducers/PatientReducer";
import snakecaseKeys from 'snakecase-keys';
import moment from "moment";

const endPoint = 'patients';
// const pendingAction = createPendingAction(PATIENT);

const extraConfig = {
    createBody: (state) => {
        const { form } = state;
        const postData = {};
        const patient = snakecaseKeys(form);
        for (let key in patient) {
            if (patient[key] !== '') {
                postData[key] = patient[key]
            }
        }
        return {
            ...postData,
            birthday: moment(postData.birthday).format("YYYY-MM-DD")
        };
    },
    createUpdateBody: (state) => {
        const { form } = state;
        const postData = {};
        const patient = snakecaseKeys(form);
        for (let key in patient) {
            if (patient[key] !== '') {
                postData[key] = patient[key]
            }
        }
        return {
            ...postData,
            birthday: moment(postData.birthday).format("YYYY-MM-DD")
        };
    },
    afterCreateSuccess: function* () {
        yield put(patientAction.dialogCreate(false));
    },
    afterCreateFailure: function* () {
        yield put(patientAction.dialogCreate(false));
    },
    afterUpdateSuccess: function* () {

    },
    afterUpdateFailure: function* () {

    },
    afterDeletedSuccess: function* () {
        yield put(patientAction.dialogDelete(false));
    },
    afterDeletedFailure: function* () {
        yield put(patientAction.dialogDelete(false));
    },
    routine: [
        fork(function* () {
            yield takeLatest(patientAction.select, function* () {
                let state = yield select(selectPatient);
                yield put(patientAction.setForm({
                    ...state.selectInstance
                }));
            });
        })
    ]
}

export default createDefaultSagasRoutine(PATIENT, endPoint, patientActionType, selectPatient, extraConfig);

