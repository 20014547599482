import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from "redux-saga";
import storage from 'redux-persist/lib/storage';
import reducers from "../reducers";
import rootSaga from "../sagas";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: [
        'authenticate',
    ],
};
const configureAppStore = (preloadedState) => {
    const sageMiddlewares = createSagaMiddleware();
    const middlewares = [sageMiddlewares, ];
    const persistedReducer = persistReducer(persistConfig, reducers);

    const store = configureStore({
        reducer: persistedReducer,
        middleware: [...getDefaultMiddleware({
            thunk: false,
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }), ...middlewares],
        preloadedState,
    })

    sageMiddlewares.run(rootSaga);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('../reducers', () => store.replaceReducer(reducers))
    }

    return store
}

const globalStore = configureAppStore();
export const persistor = persistStore(globalStore);

export default globalStore;
