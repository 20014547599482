import { createFailureAction, createSuccessAction } from "../createAction";
import { call, put, select } from "redux-saga/effects";
import httpClient from "../../../Api/httpClient";
import _ from "lodash";

export function* deleteInstance(endpoint, appActions, selector, pendingAction, extraConfig, action) {
    const successAction = createSuccessAction(action?.type);
    const failureAction = createFailureAction(action?.type);
    yield put(pendingAction());
    let state = yield select(selector);
    try {
        const req = yield call(async () => {
            const response = await httpClient.delete(`${endpoint}/${state.selectInstance.id}`);
            return response.data;
        });
        yield put(successAction({ result: req }));
        yield put({ type: appActions.get });
        if (_.isFunction(extraConfig.afterDeletedSuccess)) {
            yield call(extraConfig.afterDeletedSuccess);
        }
    } catch (error) {
        yield put(failureAction({ result: error.response }));
        if (_.isFunction(extraConfig.afterDeletedFailure)) {
            yield call(extraConfig.afterDeletedFailure);
        }
    }
}
