import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
//==================================== MUI-IMPORT ====================================================================//
import {makeStyles} from '@material-ui/core/styles';
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
import AppNavBar from "./AppBar";
import SideBar from "./Sidebar";
import Container from "@material-ui/core/Container";
import Footer from "./Footer";
//==================================== Utility =======================================================================//
//==================================== Redux action ==================================================================//
//==================================== Styles ========================================================================//
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 'calc(var(--vh, 1vh) * 100)',
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    content: {
        flexGrow: 1,
        height: `100%`,
        width: "100%",
        maxWidth: 'calc(100vw - 240px)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'unset'
        }
        // overflow: 'visible',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        height: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    containerAdmin: {
        height: 'calc((var(--vh, 1vh) * 100) - (48px + 32px))',
        '@media (min-width: 600px)': {
            height: 'calc((var(--vh, 1vh) * 100) - (64px + 32px))',
        },
        '@media (min-width: 0px) and (orientation: landscape)': {
            height: 'calc((var(--vh, 1vh) * 100) - (64px + 32px))',
        },
        '@media (max-height: 500px) and (orientation: landscape)': {
            height: 'auto',
        },
        [`${theme.breakpoints.down('md')} and @media (orientation: portrait)`]: {
            height: 'auto',
            paddingBottom: 48
        },
        overflow: 'auto',
    }
}));
//==================================== Component definition ===========================================================//
const AdminLayout = (props) => {
    const {useContainer,} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppNavBar/>
            <SideBar/>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <div className={clsx(classes.containerAdmin)}>
                    {useContainer ?
                        <Container maxWidth={props.fullWidth ? false : "lg"}
                                   className={classes.container}>
                            {props.children}
                        </Container> :
                        props.children
                    }
                </div>
                <Footer appName={props.appName}/>
            </main>
        </div>
    );
}
//==================================== Component props ===============================================================//
AdminLayout.propTypes = {
    useContainer: PropTypes.bool,
    appName: PropTypes.string
};
AdminLayout.defaultProps = {
    useContainer: false,
    appName: ""
};
//==================================== Redux map props ===============================================================//
//==================================== Export ========================================================================//
export default AdminLayout;
