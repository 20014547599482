import React from 'react';
import {connect} from 'react-redux';
import clsx from "clsx";
//==================================== MUI-IMPORT ====================================================================//
import {alpha,} from '@material-ui/core/styles/colorManipulator';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Drawer from "@material-ui/core/Drawer";
import {makeStyles, } from '@material-ui/core/styles';
import ActiveLink from "./ActiveLink";
import Typography from "@material-ui/core/Typography";
//==================================== Layout ========================================================================//
//==================================== Icon ==========================================================================//
//==================================== App component =================================================================//
import UserProfile from "../../SideBar/UserProfile";
import Display from "../../Display";
//==================================== Utility =======================================================================//
import useResponsive from "../../../util/useResponsive";
import {useHistory} from "react-router-dom";
//==================================== Redux action ==================================================================//
import {
    toggleSideBarAction
} from "../../../redux/reducers/LayoutReducer";
import {
    logoutAction
} from "../../../redux/Actions/Authentication";
import {
    selectAuthenticateUser,
    selectLayout
} from "../../../redux/selector";

//==================================== Styles ========================================================================//
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    drawerRoot: {
        height: '100%'
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
        },
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('xs')]: {
            width: 0,
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        ...theme.mixins.toolbar,
    },
    sideBarIcon: {
        fontSize: "1.7rem"
    },
    active: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.24),
        '&:hover': {
            backgroundColor: alpha(theme.palette.secondary.light, 0.24)
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: 5,
            height: "100%",
            top: 0,
            borderRadius: 5,
            left: 0,
            background: theme.palette.primary.dark
        },
        '&:focus': {
            backgroundColor: alpha(theme.palette.secondary.light, 0.24)
        },
        '& i': {
            color: theme.palette.secondary.dark
        }
    },

}));
//==================================== Component definition ===========================================================//
const SideBar = (props) => {
    const classes = useStyles();
    const {showSidebar, sidebarMenus, user} = props;
    const {isMdUp} = useResponsive();
    const history = useHistory();

    return (
        <nav className={classes.drawer}>
            <Drawer
                variant={isMdUp ? "permanent" : "temporary"}
                open={showSidebar || isMdUp}
                onClose={() => props.toggleSideBarAction(false)}
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: !isMdUp, // Better open performance on mobile.
                }}
            >
                <Display show={isMdUp}>
                    <div className={classes.toolbarIcon}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap>
                            {props.appName}
                        </Typography>
                    </div>
                    <Divider/>
                </Display>
                <UserProfile username={`${user?.firstName} ${user?.lastName ? user?.lastName : ''}`}
                             avatar={user?.avatar}
                             email={user?.email}/>
                <Divider/>
                <div>
                    {Object.values(sidebarMenus).map((x, idx) => {
                        return (
                            <List key={idx}>
                                <Display show={!!x.title}>
                                    <ListSubheader disableSticky>{x.title}</ListSubheader>
                                </Display>
                                {
                                    x.menu.map((m, midx) => (
                                        <ActiveLink activeClassName={classes.active} href={m.path} key={midx}>
                                            <ListItem button onClick={() => {
                                                history.push(m.path);
                                            }}>
                                                <ListItemIcon>
                                                    <i className={clsx(m.menuIcon, classes.sideBarIcon)}></i>
                                                </ListItemIcon>
                                                <ListItemText primary={m.menuTitle}/>
                                            </ListItem>
                                        </ActiveLink>
                                    ))
                                }
                            </List>
                        )
                    })}
                    <ListItem button onClick={() => {
                        props.logoutAction();
                    }}>
                        <ListItemIcon>
                            <i className={clsx("zmdi zmdi-lock-outline", classes.sideBarIcon)}></i>
                        </ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItem>
                </div>
            </Drawer>
        </nav>
    );
}
//==================================== Component props ===============================================================//
//==================================== Redux map props ===============================================================//
const mapStateToProps = (state) => {
    return {
        ...selectLayout(state),
        user: selectAuthenticateUser(state),
    };
};
//==================================== Export ========================================================================//
export default connect(mapStateToProps, {
    toggleSideBarAction,
    logoutAction,
})(SideBar);
