import { createTheme, responsiveFontSizes } from "@material-ui/core";

const theme = createTheme({
    palette: {
        type: "light",
        common: {
            black: "#000",
            white: "#fff"
        },
        primary: {
            light: "#A7FFEB",
            main: "#64FFDA",
            dark: "#1DE9B6",
            contrastText: "#fff"
        },
        secondary: {
            light: "#ffbccc",
            main: "#ff6488",
            dark: "#f82656",
            contrastText: "#fff"
        },
        error: {
            light: "#FF7779",
            main: "#FF3739",
            dark: "#C10002",
            contrastText: "#fff"
        },
        warning: {
            light: "#ffb74d",
            main: "#ff9800",
            dark: "#f57c00",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        info: {
            light: "#64b5f6",
            main: "#2196f3",
            dark: "#1976d2",
            contrastText: "#fff"
        },
        success: {
            light: "#ACFC93",
            main: "#78D25B",
            dark: "#5F9F4A",
            contrastText: "rgba(0, 0, 0, 0.87)"
        },
        text: {
            primary: "#242A37",
            secondary: "#222223"
        }
    },
    rounded: {
        small: '8px',
        medium: '12px',
        big: '20px',
    },
    overrides: {
        MuiInputLabel: { // Name of the component ⚛️ / style sheet
            root: { // Name of the rule
                "&$focused": { // increase the specificity for the pseudo class
                    color: "#CE7803"
                }
            }
        }
    }

});

export default responsiveFontSizes(theme);
