import { createAction } from "@reduxjs/toolkit";
import _ from "lodash";

const createServerReducerMixin = (name) => {
    return {
        tablePageChange: (state, action) => {
            state.server.currentPage = action.payload;
            if (_.has(state, 'isLoading')) {
                state.isLoading = true;
            }
            return state;
        },
        tablePageSizeChange: (state, action) => {
            state.server.sizePage = action.payload;
            state.server.currentPage = 0;
            if (_.has(state, 'isLoading')) {
                state.isLoading = true;
            }
            return state;
        },
        resetTable: (state) => {
            state.server.sizePage = 5;
            state.server.currentPage = 0;
            if (_.has(state, 'isLoading')) {
                state.isLoading = true;
            }
            return state;
        },
        search: (state, action) => {
            const { payload } = action;
            state.filter.active = true;
            state.filter.search = payload;
            state.server.currentPage = 0;
            return state;
        },
        updateFilter: (state, action) => {
            const { payload } = action;
            state.filter.active = true;
            state.filter[payload.topic] = payload.value;
            return state;
        },
        sortFilter: (state, action) => {
            const { payload } = action;
            state.filter.active = true;
            state.filter.sortColumn = payload.column;
            state.filter.dir = payload.dir;
            return state;
        },
        clearFilter: (state) => {
            const filter = { ...state.filter };
            Object.keys(filter).map((x) => filter[x] = '');
            state.server.currentPage = 0;
            return {
                ...state,
                filter: {
                    ...filter,
                    active: false,
                    search: '',
                    sortColumn: '',
                    dir: '',
                }
            };
        },
    };
};

const createServerAction = (name) => {
    return {
        action: {
            tablePageChange: createAction(`${name}/tablePageChange`),
            tablePageSizeChange: createAction(`${name}/tablePageSizeChange`),
            resetTable: createAction(`${name}/resetTable`),
            search: createAction(`${name}/search`),
            updateFilter: createAction(`${name}/updateFilter`, (topic, value) => ({ payload: { topic, value } })),
            sortFilter: createAction(`${name}/sortFilter`, (column, dir) => ({ payload: { column, dir } })),
            clearFilter: createAction(`${name}/clearFilter`),
        },
        actionType: {
            tablePageChange: `${name}/tablePageChange`,
            tablePageSizeChange: `${name}/tablePageSizeChange`,
            resetTable: `${name}/resetTable`,
            search: `${name}/search`,
            updateFilter: `${name}/updateFilter`,
            sortFilter: `${name}/sortFilter`,
            clearFilter: `${name}/clearFilter`,
        }
    }
};

export {
    createServerAction,
    createServerReducerMixin
}
