export const createPendingAction = (type) => {
    return (payload) => ({
        type: `${type}/pending`,
        payload: payload
    });
};

export const createSuccessAction = (type) => {
    return (payload) => ({
        type: `${type}/success`,
        payload: payload
    });
};

export const createFailureAction = (type) => {
    return (payload) => ({
        type: `${type}/failure`,
        payload: payload
    });
};
