import React from 'react';

const sideBar = {
    mainMenu: {
        title: 'Menu',
        disable: false,
        menu: [
            {
                'menuTitle': 'Dashboard',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-view-dashboard',
                'path': '/admin/dashboard',
            },
            {
                'menuTitle': 'ระเบียนผู้ป่วย',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-box-mail',
                'path': '/admin/patient',
            },
            {
                'menuTitle': 'ประเมินสุขภาพ',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-box-mail',
                'path': '/admin/heath-checkup',
            },
            {
                'menuTitle': 'การประเมินภาวะหกล้ม',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-box-mail',
                'path': '/admin/fall-risk-assessment',
            },
            {
                'menuTitle': 'TUGT',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-box-mail',
                'path': '/admin/tugt',
            },
            {
                'menuTitle': 'MNA',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-box-mail',
                'path': '/admin/mna',
            },
            // {
            //     'menuTitle': 'MNA2',
            //     'newItem': false,
            //     'menuIcon': 'zmdi zmdi-account-box-mail',
            //     'path': '/admin/mna2',
            // },
            {
                'menuTitle': 'ADL',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-box-mail',
                'path': '/admin/adl-form',
            },
            {
                'menuTitle': 'UrinaryProblem',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-box-mail',
                'path': '/admin/urinary-form',
            },
            {
                'menuTitle': 'User',
                'newItem': false,
                'menuIcon': 'zmdi zmdi-account-circle',
                'path': '/admin/user',
            },

        ]
    },
}


export default sideBar;
