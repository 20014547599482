import {createSelector} from "@reduxjs/toolkit";
import _ from 'lodash';
import { patentEntitiesSelector } from "../reducers/PatientReducer";
import { createDefaultSelector} from "../../util/rudux-sagas/selector";

const selectTopic = 'patient'

export const {
    selectPatient,
    selectPatientLoading,
    selectPatientEntities,
    selectPatientIsSelectInstance,
    selectPatientTable,
    selectPatientDialog,
    selectPatientForm,
    selectPatientFilter,
} = createDefaultSelector(selectTopic, patentEntitiesSelector);

export const selectPatientState = createSelector(
    state => _.get(state, selectTopic),
    store => store
);

export const selectPatientInstance = createSelector(
    selectPatientState,
    store => store.selectInstance
);

export const selectPatientDataTableEntities = createSelector(
    selectPatientState,
    store => {
        const entities = patentEntitiesSelector().selectAll(store);
        const data = _.toArray(entities);
        return data.map((x) => ({
            ...x,
        }));
    }
);

export const selectPatientSearchEntities = createSelector(
    selectPatientDataTableEntities,
    store => {
        return store.map((x) => ({
            ...x,
            label: `${x.hnNumber} : ${x.name}`
        }));
    }
);

