import { createFailureAction, createSuccessAction } from "../createAction";
import { call, put, select } from "redux-saga/effects";
import httpClient from "../../../Api/httpClient";
import _ from "lodash";

export function* updateInstance (endpoint, appActions, selector, pendingAction, extraConfig, action ) {
    const successAction = createSuccessAction(action?.type);
    const failureAction = createFailureAction(action?.type);
    yield put(pendingAction());
    let state = yield select(selector);
    try {
        const postData = extraConfig.createUpdateBody(state);
        const req = yield call(async () => {
            const response = await httpClient.put(`${endpoint}/${state.selectInstance.id}`, postData);
            return response.data;
        });
        yield put(successAction({ result: req }));

        if (_.get(extraConfig, 'fetchAfterCreateUpdate', true)) {
            yield put({ type: appActions.get });
        }

        if (_.get(extraConfig, 'resetFormAfterCreateUpdate', true)) {
            yield put({ type: appActions.formReset });
        }
        if (_.isFunction(extraConfig.afterUpdateSuccess)) {
            yield call(extraConfig.afterUpdateSuccess);
        }
    } catch (error) {
        yield put(failureAction({ result: error.response }));
        if (_.isFunction(extraConfig.afterUpdateFailure)) {
            yield call(extraConfig.afterUpdateFailure);
        }
    }
}
