// import jwt from 'jsonwebtoken';
import qs from 'qs';
import camelcaseKeys from "camelcase-keys";
import _ from 'lodash';
import httpClient, {backendConfig} from '../Api/httpClient';

export const saveUserMeta = (data) => {
    const strData = JSON.stringify(
        _.pick(data, [
            'info',
        ]),
    );
    localStorage.setItem('userMeta', strData);
};

export const loadUserMeta = () => {
    const meta = localStorage.getItem('userMeta');
    let data = null;
    if (meta) {
        data = JSON.parse(meta);
    }
    return data;
};

export const localStoreClear = () => {
    localStorage.clear();
};

export const localTokenStoreLoader = () => ({
    accessToken: localStorage.getItem('accessToken') ?? '',
    refreshToken: localStorage.getItem('refreshToken') ?? '',
    expiresIn: localStorage.getItem('expiresIn') ?? '',
    tokenType: localStorage.getItem('tokenType') ?? '',
});

export const localTokenStoreSave = (token) => {
    localStorage.setItem('accessToken', token.accessToken ?? '');
    localStorage.setItem('refreshToken', token.refreshToken ?? '');
    localStorage.setItem('expiresIn', token.expiresIn ?? '');
    localStorage.setItem('tokenType', token.tokenType ?? '');
};

export const isTokenExpires = (expiresTime) => {
    let result = false;
    const currentDate = new Date().getTime();
    if (typeof expiresTime === 'number') {
        const diff = expiresTime - currentDate / 1000;
        // Remaining time in second
        if (diff < 600) {
            result = true;
        }
    }
    return result;
};

export const extractToken = (token) => {
    let payload = {};
    let isExpire = true;
    try {
        payload = camelcaseKeys(token);
        const expiresInMilliSeconds = payload.expiresIn * 1000;
        const now = new Date();
        const expiresAt = now.getTime() + expiresInMilliSeconds;
        isExpire = isTokenExpires(expiresAt);
        // eslint-disable-next-line no-empty
    } catch (e) {
    }
    return {
        ...payload,
        isExpire,
        tokenExist: !!token,
        // isGuest: !payload?.customerUuid,
    };
};

export const saveTokenToStorage = (data) => {
    const tokenData = extractToken(data);
    const saveToken = {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken ?? '',
        expiresIn: tokenData.exp,
        tokenType: tokenData.typ ?? data.tokenType,
    };

    httpClient.applyToken(saveToken);
    localTokenStoreSave(saveToken);
    return tokenData;
};

export const revokeToken = async (token) => {
    // TODO
    // Waiting for spec
    localStoreClear();
}

export const obtainToken = async (user, password) => {
    let tokenData;
    let responseBody;
    try {
        const response = await httpClient
            .getClient()
            .post('authorized/login/', qs.stringify({
                username: user,
                password: password,
                grant_type: 'password',
                'client_id': backendConfig.clientId,
                'client_secrect': backendConfig.clientSecrect
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });
        responseBody = camelcaseKeys(response);
        tokenData = saveTokenToStorage(responseBody.data);
        saveUserMeta(responseBody?.data?.data);
    } catch (e) {
        throw e;
    }
    const {scope} = tokenData;

    return {
        ...tokenData,
        ..._.omit(responseBody?.data?.data, [
            'accessToken',
            'refreshToken',
            'expiresIn',
            'tokenType',
        ]),
        scope,
    };
};

export const obtainGuestToken = async () => {
    let req;
    try {
        const body = qs.stringify({
            grant_type: 'client_credentials',
            scope: 'service-api/general',
        });
        const config = {
            auth: {
                username:
                    process.env.REACT_APP_AUTH_GUEST_USER ?? '6289kge11s9ea42cpp078h1l31',
                password:
                    process.env.REACT_APP_AUTH_GUEST_PASSWORD
                    ?? '64lttiuctufb0gqpqr6hv76cvv1gemfd82c22vpg88vqkf0q9mr',
            },
        };

        req = await httpClient.authenClient.post('/oauth2/token', body, config);
    } catch (e) {
        throw e.request;
    }
    return req?.data;
};

export const refreshToken = async (refreshToken) => {
    try {
        const response = await httpClient
            .getClient()
            .post('/customer/v0/customers/login/refresh-token', {
                refreshToken,
            });
        const data = camelcaseKeys(response);
        saveTokenToStorage(data.data);
    } catch (e) {
        throw e.request;
    }
};

export const preAuthCheck = async () => {
    const token = localTokenStoreLoader();
    let tokenData = extractToken(token.accessToken);
    let response;
    let applyToken;

    if (tokenData.isExpire) {
        if (!tokenData.isGuest) {
            // TODO
            // Mock data
            // Renew token with member session
            // response = refreshToken(token.refreshToken);
            // applyToken = response.data;
            // saveTokenToStorage(applyToken);
        } else {
            // Obtain token with guest session
            try {
                const response = await obtainGuestToken();
                applyToken = camelcaseKeys(response);
                saveTokenToStorage(applyToken);
            } catch (err) {
            }
        }
    } else {
        httpClient.applyToken({
            ...tokenData,
            accessToken: token.accessToken,
            refreshToken: token.refreshToken ?? '',
            expiresIn: tokenData.exp,
            tokenType: tokenData.typ,
        });
    }

    return {
        result: tokenData,
        isGuest: tokenData.isGuest,
    };
};
